<template>
  <div class="index_page">
    <div class="content_container">
      <routeHeader selectedKey="index" />
      <div class="content">
        <div class="table">
          <div class="table_header">
            <span>账号安全</span>
            <span class="cl-click pointer" @click="onLogout">退出登录</span>
          </div>
          <div class="table_item">
            <div class="g-flex-col">
              <span class="tip">手机号</span>
              <span>{{ companyInfo.mobilePhoneStr }}</span>
            </div>
            <div class="button" @click="onChangePhone">更改</div>
          </div>
          <div class="table_item">
            <div class="g-flex-col">
              <span class="tip">密码</span>
              <span>{{ companyInfo.isPassword ? '已设置，可通过账号密码登录' : '未设置密码' }}</span>
            </div>
            <div class="button" @click="editPassword = true">{{ companyInfo.isPassword ? '更改' : '设置' }}</div>
          </div>
          <div class="table_item">
            <div class="g-flex-col">
              <span class="tip">千川搬家</span>
            </div>
            <div class="button" @click="toQcbj">进入</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <span @click="sendUpdatePasswordCaptcha">获取验证码</span>
    <span @click="setPassword">设置密码</span>
    <span @click="sendLoginCaptcha">获取修改手机号验证码</span>
    <span @click="updateMobile">修改手机号</span>-->
    <a-modal v-model="editPassword" title="修改密码" @ok="setPassword">
      <a-form>
        <a-form-item label :label-col="{ span: 0 }" :wrapper-col="{ span: 16 }">
          <div class="captcha-container">
            <a-input placeholder="请输入验证码" v-model="params.captcha"></a-input>
            <span class="captcha-button" @click="sendUpdatePasswordCaptcha" v-if="params.waitTime > 59">发送验证码</span>
            <span class="captcha-count-down-button g-disable-button" style="border-radius:0 6px 6px 0" v-else disable>{{ params.waitTime }}s后可重新获取</span>
          </div>
        </a-form-item>
        <a-form-item label :label-col="{ span: 0 }" :wrapper-col="{ span: 16 }">
          <a-input-password placeholder="请输入密码" v-model="params.password" style="width:336px" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="editMobilePhone" title="修改手机号" @ok="handleOk">
      <a-form>
        <a-form-item label :label-col="{ span: 0 }" :wrapper-col="{ span: 16 }">
          <a-input placeholder="请输入手机号" v-model="mobilePhone" style="width:336px"></a-input>
        </a-form-item>
        <a-form-item label :label-col="{ span: 0 }" :wrapper-col="{ span: 16 }">
          <div class="captcha-container">
            <a-input placeholder="请输入验证码" v-model="captcha"></a-input>
            <span class="captcha-button" @click="onCaptchaClick" v-if="waitTime > 59">发送验证码</span>
            <span class="captcha-count-down-button g-disable-button" style="border-radius:0 6px 6px 0" v-else disable>{{ waitTime }}s后可重新获取</span>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import regular from '@l/regular'
export default {
  data () {
    return {
      params: {
        captcha: '',
        password: '',
        waitTime: 60
      },
      captcha: '',
      mobilePhone: '',
      waitTime: 60,
      mobilePhoneParams: {

      },
      editMobilePhone: false,
      editPassword: false,
      companyInfo: {}
    }
  },
  mounted () {
    this.getCompany()
  },
  methods: {
    toQcbj () {
      const token = localStorage.getItem('token')
      window.open('https://guanjia.zhujitech.com/#/auth?code=' + token, '_blank')
    },
    onCaptchaClick () {
      if (!this.mobilePhone) {
        // this.params.mobilePhone.help = '请输入手机号码'
        this.$message.error('请输入手机号码')
        return
      }
      if (!regular.regPhone.test(this.mobilePhone)) {
        // this.params.mobilePhone.help = '请输入正确格式的手机号码'
        this.$message.error('请输入正确格式的手机号码')
        return
      }
      this.sendLoginCaptcha()
    },
    onLogout () {
      // this.$api.userAccount.reqLogout({}).then(res => {
      localStorage.removeItem('token')
      this.resetSetItem('token', '')
      this.$router.push({ path: '/login' })
      // })
    },
    handleOk () {
      if (!this.mobilePhone) {
        // this.params.mobilePhone.help = '请输入手机号码'
        this.$message.error('请输入手机号码')
        return
      }
      if (!regular.regPhone.test(this.mobilePhone)) {
        // this.params.mobilePhone.help = '请输入正确格式的手机号码'
        this.$message.error('请输入正确格式的手机号码')
        return
      }
      if (!regular.regCaptcha.test(this.captcha)) {
        // this.params.captcha.help = '请输入正确格式的验证码'
        this.$message.error('请输入正确格式的验证码')
        return
      }
      this.updateMobile()
    },
    onChangePhone () {
      this.editMobilePhone = true
    },
    getCompany () {
      this.$api.company.getCompany({}).then(res => {
        res.mobilePhoneStr = res.mobilePhone.substr(0, 3) + '****' + res.mobilePhone.substr(7, 4)
        this.companyInfo = res
      }).catch(res => {
      })
    },
    sendLoginCaptcha () {
      this.$api.account.sendLoginCaptcha({ mobilePhone: this.mobilePhone }).then(res => {
        // this.captcha = res.captcha
        this.countdown()
      }).catch(res => {
      })
    },
    updateMobile () {
      this.$api.account.updateMobile({ mobilePhone: this.mobilePhone, captcha: this.captcha }).then(res => {
        this.editMobilePhone = false
        this.getCompany()
      }).catch(res => {
      })
    },
    sendUpdatePasswordCaptcha () {
      this.$api.account.sendUpdatePasswordCaptcha({}).then(res => {
        this.countdownPassword()
        // this.params.captcha = res.captcha
      })
    },
    setPassword (res) {
      if (!regular.regCaptcha.test(this.params.captcha)) {
        this.$message.error('请输入正确格式的验证码')
        return
      }
      if (!regular.regPassword.test(this.params.password)) {
        this.$message.error('请输入正确格式的密码')
        return
      }
      this.$api.account.setPassword(this.params).then(res => {
        this.editPassword = false
        this.getCompany()
      })
    },
    // ------------------ 其它
    countdownPassword () {
      const _this = this
      if (this.params.waitTime === 0) {
        this.params.waitTime = 60
      } else {
        this.params.waitTime--
        setTimeout(function () {
          _this.countdownPassword()
        }, 1000)
      }
    },
    countdown () {
      const _this = this
      if (this.waitTime === 0) {
        this.waitTime = 60
      } else {
        this.waitTime--
        setTimeout(function () {
          _this.countdown()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.index_page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f3f3f3;
  width: 100%;
  padding: 24px;
  height: 100%;

  .content_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .content {
    flex: 1;
    display: flex;
    background: #fff;
    width: 100%;
    overflow: auto;
    padding: 24px;

    .table {
      display: flex;
      flex-direction: column;
      border: 0.5px solid #eeeeee;
      width: 100%;

      .table_header {
        background: #fafafa;
        border: 0.5px solid #eeeeee;
        color: #282828;
        font-size: 13px;
        padding: 15px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .table_item {
        border: 0.5px solid #eeeeee;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #555;
        padding: 15px 20px;

        .tip {
          color: #bbb;
        }
      }
    }
  }
}

.button {
  height: 28px;
  background: #ffffff;
  border: 1px solid #2878ff;
  border-radius: 14px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2878ff;
  cursor: pointer;
}

.captcha-container {
  .g-flex-row;
  width: 336px;
}

.captcha-button {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  border-radius: 0 6px 6px 0;
  border: 1px solid #2878ff;
  border-radius: 2px;
  color: #2878ff;
  flex-shrink: 0;
  .pointer;
}

.captcha-count-down-button {
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 2px;
  flex-shrink: 0;
}
</style>
